import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisArea = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.916 19.915H4.086V4.085h15.83v15.83zm-15.319-.511h14.809V4.595H4.597v14.809z"
        fill="currentColor"
      />
      <path
        d="M18.128 7.404l-3.064 1.532L12 13.532 8.936 12l-3.064 1.532v4.596h12.256V7.404z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisArea
