import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeDimensionGroup = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.889 6H20.11C21.15 6 22 6.9 22 8v6c0 1.1-.85 2-1.889 2H6.89C5.85 16 5 15.1 5 14V8c0-1.1.85-2 1.889-2zm0 8H20.11V8H6.89v6zm-3-4C2.85 10 2 10.81 2 11.8v5.4c0 .99.85 1.8 1.889 1.8H17.11C18.15 19 19 18.19 19 17.2H3.889V10z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIdeDimensionGroup
