import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCrossFilter = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.16 11.22C22.16 6.13 18.096 2 13.083 2c-.118 0-.236.004-.353.008v10.018h9.395c.023-.266.035-.534.035-.805zM11.077 21.999c4.746 0 8.64-3.7 9.042-8.416h-9.394V3.564C5.875 3.753 2 7.805 2 12.777c0 5.093 4.064 9.221 9.077 9.221z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCrossFilter
