import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgFindSelected = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M17.01 14h-.8l-.27-.27a6.451 6.451 0 001.57-4.23 6.5 6.5 0 00-6.5-6.5c-3.59 0-6.5 3-6.5 6.5H2l3.84 4 4.16-4H6.51a4.5 4.5 0 019 0 4.507 4.507 0 01-6.32 4.12L7.71 15.1a6.474 6.474 0 007.52-.67l.27.27v.79l5.01 4.99L22 19l-4.99-5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFindSelected
