import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgMultiSelect = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.2 4.8v10.8H8.4V4.8h10.8zm0-1.8H8.4c-.99 0-1.8.81-1.8 1.8v10.8c0 .99.81 1.8 1.8 1.8h10.8c.99 0 1.8-.81 1.8-1.8V4.8c0-.99-.81-1.8-1.8-1.8zm-6.777 10.8L9.3 10.65l1.26-1.269 1.863 1.872L17.04 6.6l1.26 1.269-5.877 5.931zM4.8 6.6H3v12.6c0 .99.81 1.8 1.8 1.8h12.6v-1.8H4.8V6.6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMultiSelect
