import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgModelFile = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.5 4h-15C3.125 4 2 4.9 2 6v12c0 1.1 1.125 2 2.5 2h15c1.375 0 2.5-.9 2.5-2V6c0-1.1-1.125-2-2.5-2zm-.24 9H4.76v-2h14.5v2zm-5 4h-9.5v-2h9.5v2zm5-8H4.76V7h14.5v2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgModelFile
