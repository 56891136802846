import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Colors, FontFamilies, FontSizes } from '../shared/styles';

const StyledWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: ${FontFamilies.regular};
  font-size: ${FontSizes.s3};
  cursor: pointer;
`;

const StyledRadio = styled.div<{ checked?: boolean; disabled?: boolean }>`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
	margin-right: 8px;
  border: 1px solid
  border-color: ${({ disabled }) => (disabled ? Colors.gray : Colors.blue)}
  border-radius: 100%;
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 60%;
    background-color: ${({ checked, disabled }) =>
      checked ? (disabled ? Colors.gray : Colors.blue) : 'initial'};
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }
`;

export interface RadioProps {
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}

export const Radio: React.FunctionComponent<RadioProps> = ({
  checked = false,
  disabled,
  onClick,
  className,
  children,
}) => (
  <StyledWrapper className={className} onClick={onClick}>
    <StyledRadio checked={checked} disabled={disabled} />
    {children}
  </StyledWrapper>
);
