import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgChartTimeline = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19 13H5v-2h14v2zm2 4H7v-2h14v2zM17 7v2H3V7h14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChartTimeline
