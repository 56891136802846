import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgPin = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.031 12V4h1V2h-10v2h1v8l-2 2v2h5.2v6h1.6v-6h5.2v-2l-2-2zm-7.2 2l1.2-1.2V4h4v8.8l1.2 1.2h-6.4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPin
