import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgShareAlt = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M14.899 8.911v1.52h3.576v10.05H6.519v-10.05h3.576V8.91h-3.62C5.49 8.911 5 9.403 5 10.386v10.196c.022.946.513 1.418 1.475 1.418h12.044c.962 0 1.453-.472 1.475-1.418V10.387c0-.984-.491-1.476-1.475-1.476h-3.62zm-2.994-6.69L8.386 5.748l1.07 1.076 2.228-2.228v9.057h1.519V4.595l2.221 2.228L16.5 5.747l-3.519-3.525A.73.73 0 0012.443 2a.73.73 0 00-.538.222z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgShareAlt
