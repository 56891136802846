import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeFileModel = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.243 7.757a1 1 0 01.263.95l-1.415 5.657a1 1 0 01-.727.727l-5.657 1.415a1 1 0 01-1.213-1.213L8.91 9.636a1 1 0 01.727-.728l5.657-1.414a1 1 0 01.95.263zm-5.54 2.946l-.864 3.458 3.457-.864-2.593-2.594z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2a8 8 0 100-16 8 8 0 000 16z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIdeFileModel
