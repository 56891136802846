import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisSingleValue = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.54 12.786c0-2.12.412-3.704 1.235-4.754.823-1.05 2.042-1.575 3.655-1.575.555 0 .993.047 1.312.14v1.055a4.207 4.207 0 00-1.298-.185c-1.156 0-2.038.36-2.648 1.08-.609.72-.944 1.853-1.003 3.397h.089c.54-.846 1.396-1.268 2.566-1.268.97 0 1.732.292 2.29.878.558.585.838 1.379.838 2.382 0 1.122-.306 2.003-.918 2.645-.612.642-1.44.963-2.482.963-1.117 0-2.002-.42-2.656-1.258-.654-.839-.98-2.006-.98-3.5zm3.622 3.718c.698 0 1.24-.22 1.626-.66.386-.44.58-1.076.58-1.907 0-.713-.18-1.273-.54-1.682-.359-.409-.894-.612-1.608-.612-.442 0-.848.091-1.217.273-.37.182-.663.433-.881.753a1.74 1.74 0 00-.328.996c0 .507.098.979.294 1.416.196.437.476.785.838 1.04.361.256.773.383 1.235.383z"
        fill="currentColor"
      />
      <path
        d="M19.973 19.915H4.143V4.085h15.83v15.83zm-15.32-.511h14.81V4.595H4.652v14.809z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisSingleValue
