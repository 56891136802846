import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgLookerLogo = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#LookerLogo_svg__clip0)" fill="currentColor">
        <path d="M6.14 0a1.742 1.742 0 00-1.445 2.714l.744-.743a.734.734 0 11.47.468l-.744.743A1.741 1.741 0 106.14 0zM5.448 5.074a2.706 2.706 0 00-.542-1.63l-.966.964a1.384 1.384 0 01-.264 1.67L4.2 7.361a2.721 2.721 0 001.248-2.287z" />
        <path d="M2.749 6.458h-.026a1.385 1.385 0 11.761-2.543l.956-.956a2.722 2.722 0 10-1.167 4.782L2.75 6.458zM6.176 7.567c-.604 0-1.205.088-1.784.262l.765 1.869a4.167 4.167 0 11-.936.362l-.757-1.864a6.178 6.178 0 102.717-.63l-.005.001zM18.336 2.555h1.737v13.11h6.393v1.65h-8.13V2.555zM28.983 16.605a4.977 4.977 0 01-1.854-1.943 5.606 5.606 0 01-.664-2.716 5.606 5.606 0 01.665-2.711 4.976 4.976 0 011.853-1.943 5.37 5.37 0 015.351 0 4.99 4.99 0 011.855 1.943c.447.835.675 1.769.664 2.716a5.619 5.619 0 01-.664 2.715 4.993 4.993 0 01-1.853 1.943 5.37 5.37 0 01-5.352 0l-.001-.004zm4.407-1.279a3.441 3.441 0 001.292-1.338c.335-.627.503-1.33.486-2.042a4.128 4.128 0 00-.486-2.042 3.441 3.441 0 00-1.289-1.338A3.397 3.397 0 0031.66 8.1a3.446 3.446 0 00-3.044 1.804 4.139 4.139 0 00-.484 2.042c-.017.711.15 1.415.484 2.043a3.462 3.462 0 004.778 1.337h-.003zM40.272 16.605a4.993 4.993 0 01-1.853-1.943 5.619 5.619 0 01-.665-2.716 5.618 5.618 0 01.665-2.711 4.992 4.992 0 011.853-1.943 5.37 5.37 0 015.352 0 4.984 4.984 0 011.854 1.943 5.62 5.62 0 01.664 2.716 5.62 5.62 0 01-.664 2.715 4.984 4.984 0 01-1.853 1.943 5.37 5.37 0 01-5.352 0l-.001-.004zm4.407-1.279c.544-.32.99-.782 1.289-1.338a4.137 4.137 0 00.486-2.042 4.14 4.14 0 00-.486-2.042 3.448 3.448 0 00-1.289-1.338 3.397 3.397 0 00-1.735-.466 3.455 3.455 0 00-1.745.466 3.42 3.42 0 00-1.298 1.338 4.128 4.128 0 00-.486 2.042c-.016.712.151 1.415.486 2.043.3.557.75 1.02 1.298 1.337.53.308 1.133.468 1.745.466a3.396 3.396 0 001.74-.466h-.005zM49.39 2.555h1.736v9.33l5.226-5.308h2.204v.082l-4.407 4.45 4.207 6.129v.077h-2.081l-3.351-5.005-1.798 1.801v3.204h-1.735V2.555z" />
        <path d="M60.599 16.625a4.815 4.815 0 01-1.795-1.913 5.785 5.785 0 01-.643-2.748c-.007-.93.2-1.848.606-2.684a4.856 4.856 0 011.725-1.973 4.59 4.59 0 012.567-.734c.991 0 1.85.221 2.578.664a4.418 4.418 0 011.679 1.834c.401.837.6 1.755.581 2.683 0 .172-.014.344-.04.515h-8.012c.04.77.225 1.417.555 1.943.305.504.74.915 1.26 1.19.491.26 1.04.396 1.596.396 1.32 0 2.318-.608 2.993-1.824l1.427.694a5.027 5.027 0 01-1.764 1.933c-.754.476-1.66.714-2.716.714a4.924 4.924 0 01-2.597-.69zm5.514-5.749a3.285 3.285 0 00-.357-1.268c-.227-.44-.57-.81-.991-1.07-.45-.292-1.017-.437-1.705-.437a2.888 2.888 0 00-2.022.763c-.554.509-.918 1.18-1.09 2.012h6.165zM69.016 6.578h1.653v1.994h.082c.205-.572.6-1.049 1.185-1.43a3.323 3.323 0 011.835-.569c.42-.01.838.066 1.226.226v1.858a3.253 3.253 0 00-1.47-.327 2.42 2.42 0 00-1.388.43c-.43.294-.777.691-1.012 1.154a3.349 3.349 0 00-.378 1.567v5.834h-1.733V6.578z" />
      </g>
      <defs>
        <clipPath id="LookerLogo_svg__clip0">
          <path d="M0 0h75v19.917H0V0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLookerLogo
