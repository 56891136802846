import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCalendarQuarter = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21 6v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14z"
        fill="currentColor"
      />
      <path
        d="M8.448 17.145c.302 0 .523-.086.664-.258.14-.175.217-.478.23-.91v-.145c0-.469-.073-.805-.219-1.008-.143-.203-.375-.304-.695-.304-.56 0-.84.44-.84 1.32 0 .437.07.764.207.98.14.216.358.325.653.325zm-.426.933c-.516 0-.92-.198-1.211-.594-.292-.398-.438-.951-.438-1.66 0-.713.149-1.27.446-1.672.3-.4.708-.601 1.226-.601.276 0 .517.052.723.156.206.104.387.266.543.484h.031l.106-.558h1.008v6.289H9.26V18.09c0-.159.017-.378.05-.656h-.05c-.127.21-.297.37-.508.48-.21.11-.454.164-.73.164zm5.12-.95c.208 0 .458-.045.75-.136v.887c-.297.133-.662.2-1.094.2-.477 0-.825-.12-1.043-.36-.216-.242-.325-.604-.325-1.086v-2.106h-.57v-.504l.656-.398.344-.922h.762v.93h1.223v.894h-1.223v2.106c0 .17.047.294.14.375a.57.57 0 00.38.12zm3.674-3.577c.161 0 .295.011.402.035l-.09 1.117a1.366 1.366 0 00-.351-.039c-.38 0-.678.098-.891.293-.211.195-.316.469-.316.82V18h-1.192v-4.367h.902l.176.734h.059c.135-.245.318-.441.547-.59.232-.15.483-.226.754-.226z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendarQuarter
