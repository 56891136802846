import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisTable = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M4 4h16v2.58H4V4zM4 8.645h16v.516H4v-.516zM4 11.398h16v.516H4v-.516zM4 14.15h16v.516H4v-.516zM4 16.903h16v.516H4v-.516zM4 19.484h16V20H4v-.516z"
        fill="currentColor"
      />
      <path
        d="M4 4h.516v16H4V4zM9.161 4h.516v16h-.516V4zM14.322 4h.517v16h-.517V4zM19.484 4H20v16h-.516V4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisTable
