import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisColumn = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.873 10.468h3.064v7.66H5.873v-7.66zM10.469 12h3.064v6.127h-3.064V12zM15.065 5.872h3.064v12.256h-3.064V5.872z"
        fill="currentColor"
      />
      <path
        d="M19.916 19.915H4.086V4.085h15.83v15.83zm-15.319-.511h14.809V4.595H4.597v14.809z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisColumn
