import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgNotificationBellOn = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.137 15.75v-5c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.68-1.5-1.51-1.5s-1.49.67-1.49 1.5v.68c-2.87.68-4.5 3.24-4.5 6.32v5l-1.3 1.29c-.63.63-.19 1.71.7 1.71h13.17c.89 0 1.34-1.08.71-1.71l-1.28-1.29zm-6.01 6c1.1 0 2-.9 2-2h-4a2 2 0 002 2zM6.907 4.48c.42-.38.43-1.03.03-1.43a1 1 0 00-1.39-.02 10.424 10.424 0 00-3.27 6.06c-.09.61.38 1.16 1 1.16.48 0 .9-.35.98-.83a8.44 8.44 0 012.65-4.94zm11.83-1.45c-.4-.37-1.02-.36-1.4.02-.4.4-.38 1.04.03 1.42 1.38 1.27 2.35 3 2.65 4.94.07.48.49.83.98.83.61 0 1.09-.55.99-1.16-.38-2.37-1.55-4.48-3.25-6.05z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNotificationBellOn
