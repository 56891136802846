import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgLockClosed = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.377 6.765a4.716 4.716 0 00-4.67-4.762h-.092a4.587 4.587 0 00-3.378 1.384 4.587 4.587 0 00-1.385 3.378V8.67h-.948A1.915 1.915 0 004 10.58v9.519c0 1.051.853 1.904 1.904 1.904h11.428a1.904 1.904 0 001.904-1.904v-9.519a1.915 1.915 0 00-1.904-1.91h-.955V6.765zm-1.807 0V8.67H8.673V6.765c-.01-.78.302-1.529.865-2.07a2.918 2.918 0 014.127-.033c.011.011.023.021.033.033a2.82 2.82 0 01.872 2.077v-.007zm-4.301 7.224a1.912 1.912 0 11-.558 1.346 1.844 1.844 0 01.558-1.346z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLockClosed
