import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisPie = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.127 12.383a6.51 6.51 0 00-6.51-6.511c-.132 0-.255.026-.383.036v-.036h-.766V7.48c-2.596.376-4.596 2.587-4.596 5.286 0 2.961 2.4 5.362 5.362 5.362 1.468 0 2.783-.61 3.75-1.567l1.148.8.471-.643-.09-.061a6.465 6.465 0 001.614-4.274zm-6.893 4.979a4.596 4.596 0 01-4.596-4.596 4.586 4.586 0 013.83-4.519V13.4l-.008.01.008.005 3.881 2.705a4.562 4.562 0 01-3.115 1.243zm4.65-1.145l-4.65-3.241v-6.3c.129-.009.252-.038.383-.038a5.744 5.744 0 015.744 5.745 5.706 5.706 0 01-1.478 3.834z"
        fill="currentColor"
      />
      <path
        d="M19.916 19.915H4.086V4.085h15.83v15.83zm-15.319-.511h14.809V4.595H4.597v14.809z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisPie
