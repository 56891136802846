import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeParameter = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5l7.013 9L19 5H5zm0 4c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-.828l-1.547 2H19v6H5v-6h2.375L5.812 9H5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIdeParameter
