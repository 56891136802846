import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgAlignRight = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 3h18v2H3V3m6 4h12v2H9V7m-6 4h18v2H3v-2m6 4h12v2H9v-2m-6 4h18v2H3v-2z" />
    </svg>
  )
}

export default SvgAlignRight
