import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgDigitalMarketingApp = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M11.815 8.102h-7.68a1.921 1.921 0 00-1.92 1.92v3.84a1.921 1.921 0 001.92 1.92h.96v3.84a.96.96 0 00.96.96h1.92a.96.96 0 00.96-.96v-3.84h2.88l4.8 3.84V4.262l-4.8 3.84zm2.88 7.296l-1.92-1.536h-8.64v-3.84h8.64l1.92-1.536v6.912zm6.24-3.456a4.24 4.24 0 01-2.4 3.84v-7.68a4.277 4.277 0 012.4 3.84z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDigitalMarketingApp
