import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgDashboardGauge = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#DashboardGauge_svg__clip0)">
        <path
          d="M14.422 9.625c-.433-.11-.916.106-1.077.485l-2.078 4.896-.018-.002a2.25 2.25 0 102.25 2.25c0-.66-.288-1.246-.739-1.657l2.16-5.09c.159-.378-.064-.772-.498-.882zm-3.172 8.38a.75.75 0 110-1.498.75.75 0 010 1.499zM12 .005c-6.627 0-12 5.37-12 12 0 6.629 5.373 11.998 12 11.998 6.625 0 12-5.37 12-11.998 0-6.628-5.373-12-12-12zm0 22.5c-5.8 0-10.5-4.701-10.5-10.5 0-5.8 4.701-10.5 10.5-10.5 5.797 0 10.5 4.7 10.5 10.5 0 5.799-4.703 10.5-10.5 10.5zm0-18a7.499 7.499 0 00-7.5 7.5H6a6 6 0 0112 0h1.5c0-4.143-3.357-7.5-7.5-7.5z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="DashboardGauge_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgDashboardGauge
