import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCalendarWeek = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21 6v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14z"
        fill="currentColor"
      />
      <path
        d="M9.494 18H8.287v-3.305l.011-.543.02-.593c-.2.2-.34.332-.418.394l-.656.527-.582-.726 1.84-1.465h.992V18zm5.08 0l-.336-1.527-.453-1.93h-.027L12.96 18H11.68l-1.242-4.367h1.187l.504 1.933c.08.347.163.825.246 1.434h.023c.01-.198.056-.512.137-.941l.063-.332.539-2.094h1.312l.512 2.094a112.335 112.335 0 00.113.613 9.643 9.643 0 01.086.66h.024c.023-.188.065-.444.125-.77.06-.328.103-.549.129-.664l.523-1.933h1.168L15.87 18h-1.297z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendarWeek
