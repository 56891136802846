import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgFlag = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.5L21 3H3v11h18l-4-5.5zm.073 3.5l-2.546-3.5L17.073 5H5v7h12.073zM3 20v-6h2v6H3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFlag
