import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgFieldTier = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 5H3v2h18V5zm0 4H7v2h14V9zm-10 4h10v2H11v-2zm10 4h-6v2h6v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFieldTier
