import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgSendSftp = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.17 8l-2-2H4v12h16V8h-8.83zM4 4h6l2 2h8c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2l.01-12c0-1.1.89-2 1.99-2zm9 12v-2H8v-2h5v-2l3 3-3 3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSendSftp
