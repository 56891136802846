import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgNotes = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 18h-6l-2 2-2-2H4a2 2 0 01-2-2V6a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2zm-6.828-2L12 17.172 10.828 16H5a1 1 0 01-1-1V7a1 1 0 011-1h14a1 1 0 011 1v8a1 1 0 01-1 1h-5.828zM7.5 9.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm3.25 1.25a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM16.5 9.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNotes
