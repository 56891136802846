import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgUpdate = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10 8v5l4.25 2.52.77-1.28-3.52-2.09V8H10zm10 2V3l-2.64 2.64A8.937 8.937 0 0011 3a9 9 0 109 9h-2c0 3.86-3.14 7-7 7s-7-3.14-7-7 3.14-7 7-7c1.93 0 3.68.79 4.95 2.05L13 10h7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUpdate
