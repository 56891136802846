import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCollapseAll = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16 5H8l4 4 4-4zM4 11h16v2H4v-2zM8 19h8l-4-4-4 4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCollapseAll
