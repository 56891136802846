import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgMore = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12 22.667c5.891 0 10.667-4.776 10.667-10.667S17.891 1.333 12 1.333 1.333 6.109 1.333 12 6.109 22.667 12 22.667zM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        fill="currentColor"
      />
      <path
        d="M12.113 13.125a1.126 1.126 0 110-2.252 1.126 1.126 0 010 2.252zm-4.663 0a1.126 1.126 0 110-2.252 1.126 1.126 0 010 2.252zm9.327 0a1.126 1.126 0 110-2.252 1.126 1.126 0 010 2.252z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMore
