import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeFileManifest = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.827 4.053l1.692-.93.963 1.752-1.69.93a1 1 0 00-.519.877v2.413a3 3 0 01-1.018 2.252l-.74.652.82.723a3 3 0 011.006 1.98l.276 3.032a1 1 0 00.593.825l1.193.525-.805 1.83-1.193-.524a3 3 0 01-1.78-2.475l-.276-3.031a1 1 0 00-.335-.66l.638-.726-.638.725L3.34 12.75v-1.502l1.594-1.402.66.75-.66-.75a1 1 0 00.34-.75V6.681a3 3 0 011.553-2.629zM17.173 19.947l-1.692.93-.963-1.752 1.69-.93a1 1 0 00.519-.877v-2.413a3 3 0 011.018-2.252l.74-.652-.82-.723a3 3 0 01-1.006-1.98l-.276-3.032a1 1 0 00-.593-.825l-1.193-.525.805-1.83 1.194.524a3 3 0 011.779 2.475l.276 3.031a1 1 0 00.335.66l-.638.726.638-.725 1.674 1.473v1.502l-1.594 1.402a1 1 0 00-.34.75v2.414a3 3 0 01-1.553 2.629z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIdeFileManifest
