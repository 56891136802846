import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgGitBranch = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 8.25c0-1.388-1.068-2.5-2.4-2.5-1.332 0-2.4 1.112-2.4 2.5a2.52 2.52 0 001.2 2.15v.375c-.024.65-.276 1.225-.756 1.725s-1.032.762-1.656.787c-.996.025-1.776.2-2.4.563V7.9a2.505 2.505 0 001.2-2.15c0-1.388-1.068-2.5-2.4-2.5C7.056 3.25 6 4.362 6 5.75A2.52 2.52 0 007.2 7.9v8.2A2.537 2.537 0 006 18.25c0 1.387 1.068 2.5 2.4 2.5 1.332 0 2.4-1.113 2.4-2.5 0-.663-.24-1.25-.636-1.7.108-.075.576-.512.708-.588.3-.137.672-.212 1.128-.212 1.26-.063 2.34-.563 3.3-1.563s1.44-2.474 1.5-3.774h-.024A2.542 2.542 0 0018 8.25zm-9.6-4c.792 0 1.44.688 1.44 1.5 0 .813-.66 1.5-1.44 1.5-.78 0-1.44-.688-1.44-1.5 0-.813.66-1.5 1.44-1.5zm0 15.512c-.792 0-1.44-.687-1.44-1.5 0-.812.66-1.5 1.44-1.5.78 0 1.44.688 1.44 1.5 0 .813-.66 1.5-1.44 1.5zm7.2-10c-.792 0-1.44-.687-1.44-1.5 0-.812.66-1.5 1.44-1.5.78 0 1.44.688 1.44 1.5 0 .813-.66 1.5-1.44 1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgGitBranch
