import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgDocumentFile = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#DocumentFile_svg__clip0)">
        <path
          d="M.012 2.4C.012 1.08 1.08 0 2.4 0H12l7.2 7.2v14.4c0 1.32-1.08 2.4-2.4 2.4H2.388A2.397 2.397 0 010 21.6L.012 2.4zM10.8 1.8v6.6h6.6l-6.6-6.6zM8.523 20.663c.818-.802 1.227-1.944 1.227-3.427 0-1.377-.389-2.431-1.167-3.16-.774-.729-1.874-1.094-3.3-1.094H2.208v8.884h2.874c1.478 0 2.625-.401 3.439-1.203h.002zm-1.75-5.152c.324.393.486.997.486 1.811 0 .871-.17 1.519-.51 1.944-.34.421-.875.632-1.604.632H4.61v-4.977h.699c.656 0 1.144.196 1.465.589l-.001.001zm9.725 5.535c.608-.632.911-1.511.911-2.637 0-.717-.142-1.343-.425-1.878a2.924 2.924 0 00-1.197-1.24c-.519-.288-1.116-.431-1.793-.431-1.094 0-1.944.312-2.552.936-.603.62-.905 1.491-.905 2.613 0 1.098.308 1.969.924 2.613.62.644 1.452.966 2.497.966 1.09 0 1.936-.314 2.54-.942zm-3.311-1.282c-.158-.312-.237-.764-.237-1.355s.079-1.035.237-1.331c.158-.3.419-.45.784-.45.373 0 .636.15.79.45.154.3.231.743.231 1.331 0 .591-.077 1.043-.231 1.355-.15.308-.409.462-.778.462-.373 0-.638-.154-.796-.462z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="DocumentFile_svg__clip0">
          <path fill="#fff" d="M0 0h19v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgDocumentFile
