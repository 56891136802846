import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgBeaker = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.75 4.502a1.249 1.249 0 100-2.5 1.25 1.25 0 100 2.5zM12 5.752a1.25 1.25 0 10-.002-2.501A1.25 1.25 0 0012 5.753zm7.22 15.01c-1.254-2.415-3.47-7.511-3.47-7.511v-5h.625a.626.626 0 000-1.25h-7.5a.626.626 0 000 1.25H9.5v5s-2.168 5.276-3.393 7.633c-.58 1.118-.58 1.118.803 1.118h11.747c.56 0 1.21 0 .565-1.24h-.001zm-8.47-7.51v-5h3.75v5l.535 1.25h-4.793c.3-.733.508-1.25.508-1.25zm6.172 7.5h-8.33c-1.153 0-1.153 0-.67-.957.513-1.015 1.223-2.658 1.803-4.043h5.855c.59 1.338 1.3 2.917 1.814 3.935.536 1.064-.005 1.064-.471 1.064z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBeaker
