import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgPublic = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#Public_svg__clip0)">
        <path
          d="M23.974 11.248C23.587 4.971 18.375-.002 12-.002S.413 4.971.026 11.248H0v1.5h.026C.413 19.025 5.625 23.997 12 23.997s11.587-4.972 11.974-11.249H24v-1.5h-.026zm-3.943-6h-1.337c-.794.221-1.762.402-2.847.531-.372-1.634-.886-3.013-1.497-4.014a10.476 10.476 0 015.68 3.482l.001.001zm-11.023 7.5h5.983a32.667 32.667 0 01-.299 3.873 29.49 29.49 0 00-5.386 0 32.88 32.88 0 01-.299-3.873h.001zm0-1.5c.028-1.384.133-2.693.299-3.877a28.745 28.745 0 005.385 0c.166 1.184.271 2.493.299 3.877H9.008zM12 1.498c1.008 0 1.899 1.742 2.443 4.412-.778.055-1.594.088-2.443.088s-1.665-.033-2.443-.088C10.1 3.24 10.991 1.498 12 1.498zm-2.352.268c-.611 1.001-1.125 2.38-1.497 4.014-1.084-.129-2.053-.31-2.847-.53H3.967a10.49 10.49 0 015.681-3.484zM3.449 5.921c1.113.558 2.643 1.002 4.428 1.275a29.286 29.286 0 00-.368 4.053H1.532c.14-1.979.832-3.804 1.917-5.328zm4.06 6.827c.033 1.431.162 2.792.367 4.044-1.786.265-3.325.695-4.458 1.238a10.44 10.44 0 01-1.885-5.282H7.51h-.001zm-3.541 6h1.239c.819-.225 1.82-.409 2.943-.539.372 1.638.887 3.02 1.499 4.022a10.478 10.478 0 01-5.681-3.483zM12 22.498c-1.008 0-1.9-1.744-2.444-4.416a35.34 35.34 0 014.888 0c-.543 2.672-1.435 4.416-2.444 4.416zm2.35-.267c.612-1.002 1.127-2.384 1.499-4.021 1.123.13 2.124.314 2.943.539h1.239a10.482 10.482 0 01-5.681 3.482zm6.231-4.201c-1.133-.543-2.672-.973-4.458-1.237.205-1.252.334-2.613.367-4.044h5.977a10.434 10.434 0 01-1.886 5.281zm-4.091-6.782a29.272 29.272 0 00-.368-4.053c1.785-.273 3.315-.717 4.428-1.275a10.45 10.45 0 011.917 5.328H16.49z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="Public_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgPublic
