import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgPivot = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3l4.33 4.5H18V16a2 2 0 01-2 2H7.5v3.33L3 17l4.5-4.33V16H16V7.5h-3.33L17 3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPivot
