import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgDoubleChevronRight = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M6 16.59L10.58 12 6 7.41 7.41 6l6 6-6 6L6 16.59z"
        fill="currentColor"
      />
      <path
        d="M11 16.59L15.58 12 11 7.41 12.41 6l6 6-6 6L11 16.59z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDoubleChevronRight
