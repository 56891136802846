import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgSortAlpha = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M14.942 4.66h-4.72l2.36-2.36 2.36 2.36zm-4.69 14.71h4.66l-2.33 2.33-2.33-2.33zm-4.15-13.1l-4.5 11.46h1.84l.92-2.45h5.11l.92 2.45h1.84L7.742 6.27h-1.64zm-1.13 7.37l1.94-5.18 1.94 5.18h-3.88zm10.76 2.5h6.12v1.59h-8.53v-1.29l5.92-8.56h-5.88v-1.6h8.3v1.26l-5.93 8.6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSortAlpha
