import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgSendWebhook = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.448 18.547c-1.488 2.11-4.392 2.64-6.492 1.172-2.089-1.467-2.578-4.392-1.11-6.522a4.673 4.673 0 013.648-2.018l.05 1.457a3.23 3.23 0 00-2.404 1.386c-1.02 1.468-.704 3.445.693 4.434 1.406.978 3.373.601 4.392-.856.316-.459.5-.958.571-1.468v-1.03l5.687-.04.071-.112c.54-.938 1.712-1.264 2.63-.734a1.936 1.936 0 01.693 2.65c-.54.927-1.723 1.253-2.64.723a1.807 1.807 0 01-.846-1.04l-4.148.021a5.056 5.056 0 01-.795 1.977zm7.42-7.276c2.578.316 4.413 2.63 4.097 5.167-.316 2.548-2.66 4.352-5.239 4.036a4.717 4.717 0 01-3.495-2.253l1.263-.733a3.28 3.28 0 002.365 1.477c1.783.214 3.363-.998 3.577-2.7.214-1.703-1.05-3.262-2.813-3.476-.55-.061-1.08.01-1.56.184l-.865.448-2.63-4.861h-.224a1.942 1.942 0 01-1.886-1.988c.031-1.06.948-1.885 2.018-1.844 1.07.06 1.916.927 1.886 1.987-.02.448-.194.856-.47 1.172l1.937 3.577a4.841 4.841 0 012.039-.193zM8.196 8.499c-1.02-2.395.061-5.137 2.415-6.136 2.365-.998 5.096.133 6.115 2.528a4.693 4.693 0 01-.204 4.148l-1.263-.734c.428-.826.499-1.835.091-2.782-.693-1.631-2.537-2.416-4.117-1.753-1.59.672-2.303 2.548-1.61 4.178a3.267 3.267 0 001.345 1.54l.397.213-3.128 5.086c.03.05.071.112.102.193.499.928.152 2.1-.785 2.6-.928.499-2.1.132-2.61-.826-.499-.948-.152-2.12.786-2.62a1.803 1.803 0 011.253-.173l2.354-3.842a4.497 4.497 0 01-1.141-1.62z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSendWebhook
