export enum Polarity {
  negative,
  positive,
  neutral,
}

export interface SimilarEventsInfo {
  resultEntries: SimilarEventResultEntry[];
  searchEntry: SimilarEventEntry;
}

export interface SimilarEventResultEntry {
  flatReportEntry: SimilarEventEntry;
  similarityScore: number;
}

export interface SimilarEventEntry {
  eventText: string;
  eventType: string;
  companyName: string;
  documentType: string;
  documentGroupId: number;
  flowId: number;
  offsetStartInText: number;
  polarity: string;
  publicationDate: string;
  sentence: string;
  ticker: string;
  sequenceNumber: number;
}

export interface Paragraph {
  id: number;
  text: string;
  textLength: number;
  offsetInText: number;
}

export interface Event {
  text: string;
  polarity: number | undefined;
  typeName: string | string[];
  id: number;
  categoryName?: string;
  startIndex?: number;
  endIndex?: number;
  startIndexAPI?: number;
  endIndexAPI?: number;
  transcriptId?: number;
  documentViewDescription?: string;
  description?: string;
  isSubsection?: boolean;
  section?: string;
  isUserDefined?: boolean;
  hide?: boolean;
  createdAt?: Date;
  userName?: string;
  status?: number;
  flow?: number;
  documentTitle?: string;
  clientName?: string;
  indexInTranscript?: number;
  systemEventId?: string;
  sentence?: string;
  ticker?: string;
  region?: string;
  countOfSimilarEvents?: number;
  similarityScore?: number;
  fullSimilarityInfo?: SimilarEventsInfo;
  paragraph?: Paragraph;
  keyDrivers?: string[];
}

export interface DropdownOption {
  label?: string;
  renderOption?: () => any;
  id: number | string;
  elementId: string;
}

export interface SectionDropdownOptions {
  id: string;
  name: string;
  action?: {
    label: string;
    onClick: () => void;
  };
  options?: DropdownOption[];
}
