import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgTable = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.878 3H4.825a2.01 2.01 0 00-2.007 2v14c0 1.1.904 2 2.007 2h15.053a2.01 2.01 0 002.007-2V5c0-1.1-.903-2-2.007-2zm0 2v3H4.825V5h15.053zM14.86 19H9.843v-9h5.018v9zM4.825 10h3.01v9h-3.01v-9zm12.043 9v-9h3.01v9h-3.01z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTable
