import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgFactCheck = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4c-1.1 0-2 .8-2 1.778v12.444C2 19.2 2.9 20 4 20h16c1.1 0 2-.8 2-1.778V5.778C22 4.8 21.1 4 20 4zM4 18V6h16v12H4zm15.5-7.5L18 9l-3 3-1.5-1.5L12 12l3 3 4.5-4.5zM5 7.555h5v1.75H5v-1.75zm5 3.562H5v1.75h5v-1.75zm-5 3.549h5v1.75H5v-1.75z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFactCheck
