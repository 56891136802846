import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgChangeHistory = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 21L12 2 1 21h22zM4.469 19H19.53L12 5.992 4.469 19z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChangeHistory
