import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgRecentActivity = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M3.001 10.12h6.78L7.041 7.3c2.73-2.7 7.15-2.8 9.88-.1a6.875 6.875 0 010 9.79 7.02 7.02 0 01-9.88 0c-1.36-1.34-2.04-2.91-2.04-4.89h-2c0 1.98.88 4.55 2.64 6.29 3.51 3.48 9.21 3.48 12.72 0 3.5-3.47 3.53-9.11.02-12.58a8.987 8.987 0 00-12.65 0L3.001 3v7.12z"
        fill="currentColor"
      />
      <path
        d="M12.5 12.25V8H11v5l4.28 2.54.72-1.21-3.5-2.08z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgRecentActivity
