import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgExpandCollapse = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13 8v3H3v2h10v3l4-4-4-4zm6 12h2V4h-2v16z" fill="currentColor" />
    </svg>
  )
}

export default SvgExpandCollapse
