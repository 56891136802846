import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { FontFamilies, FontSizes, Colors } from '../shared/styles';
import checkIcon from '../../assets/icons/checkmark.svg';
import checkIconWhite from '../../assets/icons/checkmark_white.svg';
import { Icon } from '../Icon/Icon';

const StyledWrapper = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	font-family: ${FontFamilies.regular};
	font-size: ${FontSizes.s3};
	color: ${({ disabled }) => disabled && Colors.gray}
	cursor: pointer;
	pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const StyledCheckbox = styled.div<{ checked?: boolean; disabled?: boolean }>`
  width: 19px;
  height: 19px;
  border: 1px solid;
  border-color: ${props => (props.disabled ? Colors.gray : Colors.blue)};
  border-radius: 6px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  ${({ checked, disabled }) =>
    checked &&
    disabled &&
    `
		background-color: ${Colors.gray};
	`}
`;

export interface CheckboxProps {
  id?: string;
  checked?: boolean;
  onChange?(checked: boolean): void;
  disabled?: boolean;
  children?: ReactNode;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  id,
  checked,
  onChange,
  disabled,
  children,
}) => {
  const icon = checked ? (disabled ? checkIconWhite : checkIcon) : '';

  return (
    <StyledWrapper
      id={id}
      onClick={() => onChange && onChange(!checked)}
      disabled={disabled}
    >
      <StyledCheckbox checked={checked} disabled={disabled}>
        {' '}
        <Icon src={icon} />{' '}
      </StyledCheckbox>
      {children}
    </StyledWrapper>
  );
};
