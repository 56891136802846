import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgAddAlerts = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.231 18.898a1.768 1.768 0 103.538 0H10.23zm7.885-3.725V10c0-2.889-2-5.307-4.703-5.947v-.64C13.413 2.631 12.783 2 12 2c-.782 0-1.413.631-1.413 1.413v.64A6.11 6.11 0 005.884 10v5.173L4 17.058V18h16v-.942l-1.884-1.885zm-2.56-3.386h-2.667v2.666H11.11v-2.666H8.444V10h2.667V7.333h1.778V10h2.667v1.787z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAddAlerts
