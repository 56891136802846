import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgFieldDuration = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M6.01 8L10 12l-4 4v6h12l-.01-5.99L18 16l-4-4 4-4V2H6l.01 6zm9.98 8.82L16 20H8v-3.17l3.41-3.41.59-.59.59.59 3.4 3.4zM16 4v3.17l-3.41 3.41-.59.59-.58-.58-3.41-3.42L8 4h8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFieldDuration
