export enum Appearance {
  primary,
  secondary,
  tertiary,
}

export enum LogoAppearance {
  Dark,
  DarkGroup,
  Light,
  LightGroup,
}

export enum Colors {
  lightBlue = '#DEEFFF',
  blue = '#007FFF',
  darkBlue = '#1454B2',
  dark = '#142234',
  black = '#000000',
  darkGray = '#9EA8B1',
  gray = '#D1D9E1',
  lightGray = '#F2F5F6',
  white = '#FFFFFF',
  positive = '#0EDBA0',
  warning = '#FFD75E',
  negative = '#BF2348',
  noPolarity = '#7FB7BE',
  transparentNegative = 'rgba(244,67,54,0.3)',
  transparentPositive = 'rgba(76,175,80,0.2)',
  transparentWarning = 'rgba(250, 209, 3, 0.5)',
}

export enum Sizes {
  small,
  medium,
  large,
}

export enum FontFamilies {
  regular = 'Assistant',
  extraLight = 'Assistant Extra Light',
  light = 'Assistant Light',
  semiBold = 'Assistant SemiBold',
  bold = 'Assistant Bold',
  roboto = 'Roboto',
  openSans = 'Open Sans',
}

export enum FontSizes {
  s1 = '12px',
  s2 = '14px',
  s3 = '16px',
  s4 = '18px',
  m1 = '20px',
  m2 = '24px',
  m3 = '28px',
  l1 = '32px',
  l2 = '40px',
  l3 = '48px',
}
