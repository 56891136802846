import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgScheduleOutline = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M4.01 6.03l7.51 3.22-7.52-1 .01-2.22zm7.5 8.72L4 17.97v-2.22l7.51-1zM2.01 3L2 10l15 2-15 2 .01 7L23 12 2.01 3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgScheduleOutline
