import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCaretRight = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCaretRight
