import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgDoubleChevronLeft = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.41 7.41L13.83 12l4.58 4.59L17 18l-6-6 6-6 1.41 1.41z"
        fill="currentColor"
      />
      <path
        d="M13.41 7.41L8.83 12l4.58 4.59L12 18l-6-6 6-6 1.41 1.41z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDoubleChevronLeft
