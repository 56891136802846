import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { Colors, FontFamilies, FontSizes } from '../shared/styles';

export enum BadgeType {
  full,
  outline,
}

interface StyledBadgeProps {
  type: BadgeType;
  color: Colors;
}

const StyledBadge = styled.span<StyledBadgeProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 35px;
  padding: 3px 12px;
  font-size: ${FontSizes.s3};
  font-family: ${FontFamilies.bold};
  border-radius: 20px;
  box-sizing: border-box;
  ${({ type, color }) => {
    switch (type) {
      case BadgeType.full:
        return `
        color: ${Colors.white};
        background-color: ${color};
      `;
      case BadgeType.outline:
        return `
        color: ${color};
        background-color: ${Colors.white};
        border: 1.5px solid ${color};
      `;
    }
  }}
`;

export interface BadgeProps {
  id?: string;
  color: Colors;
  type?: BadgeType;
  children?: ReactNode;
  className?: string;
}

export const Badge: FunctionComponent<BadgeProps> = ({
  id,
  color,
  type = BadgeType.full,
  children,
  className,
}) => (
  <StyledBadge id={id} color={color} type={type} className={className}>
    {children}
  </StyledBadge>
);
