import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgDimensionFill = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="currentColor" d="M8 4h14v3H8z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9h15v6H2V9zm2 2v2h11v-2H4z"
        fill="currentColor"
      />
      <path fill="currentColor" d="M8 17h14v3H8z" />
    </svg>
  )
}

export default SvgDimensionFill
