import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgQuickStart = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 4.036A1.5 1.5 0 0110.175 3h5.312a1.5 1.5 0 011.33 2.193l-1.857 3.564h1.335c1.045 0 1.629 1.206.98 2.025l-7.874 9.953c-.744.94-2.24.182-1.924-.974l1.523-5.545H7.5a1.5 1.5 0 01-1.426-1.964l2.674-8.216zM10.538 5l-2.35 7.216h3.435l-1.046 3.808 4.168-5.267h-3.082l3-5.757h-4.125z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgQuickStart
