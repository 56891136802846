import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgChartArea = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.182 8.958L2 17.834v.986h20V6l-7.273 7.89-4.545-4.932z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChartArea
