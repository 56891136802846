import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgUserAttributes = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M14 10h2v2h-2zM14 14h2v2h-2zM18 10h4v2h-4zM18 14h4v2h-4zM14 18h2v2h-2zM18 18h4v2h-4z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20v-1.5H2.5v-1.25c0-.54 2.56-1.75 5-1.75 1.786 0 3.636.648 4.5 1.208V15c-1.43-.67-3.3-1-4.5-1C5.33 14 1 15.08 1 17.25V20h11zM11 9.5c0 1.93-1.57 3.5-3.5 3.5S4 11.43 4 9.5 5.57 6 7.5 6 11 7.57 11 9.5zm-1.5 0c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUserAttributes
