import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgLogoRings = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.266 2.249a1.747 1.747 0 11-.08 2.943l.746-.746a.737.737 0 10-.47-.47l-.746.746a1.746 1.746 0 01.55-2.473zm-.344 3.204a2.715 2.715 0 01.214 2.942c-.219.4-.535.74-.918.987L9.69 8.095a1.389 1.389 0 00.263-1.675l.968-.967zM8.759 8.477h-.027a1.39 1.39 0 11.762-2.551l.96-.959a2.731 2.731 0 10-1.17 4.797l-.525-1.287zm1.652 1.376a6.191 6.191 0 011.789-.262l-.004-.001a6.196 6.196 0 11-2.726.631l.768 1.87a4.181 4.181 0 10.939-.363l-.767-1.875z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLogoRings
