import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgLogout = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a1 1 0 00-1 1v14a1 1 0 001 1h9v-2H6V6h8V4H5zm12.207 2.793l4.5 4.5.707.707-.707.707-4.5 4.5-1.414-1.414L18.586 13H11v-2h7.586l-2.793-2.793 1.414-1.414z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLogout
