import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisLine = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.916 19.915H4.086V4.085h15.83v15.83zm-15.319-.511h14.809V4.595H4.597v14.809z"
        fill="currentColor"
      />
      <path
        d="M12.145 14.08l-3.21-1.604-2.872 1.437-.381-.762 3.254-1.627 2.918 1.46 2.918-4.378 3.165-1.583.38.762-2.962 1.48-3.21 4.815z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisLine
