import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgAnalyticsApp = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M11.461 19.422c.085 0 .17-.002.254-.004l.54 1.89a9.051 9.051 0 118.219-8.185l-1.889-.538a7.13 7.13 0 10-7.124 6.837zm0-10.64a3.511 3.511 0 013.428 2.75l2 .57a5.43 5.43 0 10-5.659 5.614l-.572-2.007a3.511 3.511 0 01.803-6.928zm.54 3.723a.288.288 0 00-.357.356l2.292 8.045a.96.96 0 001.602.416l4.923-4.923a.96.96 0 00-.416-1.602L12 12.505zm3.334 6.305l-1.04-3.655 3.654 1.041-2.613 2.613v.001z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAnalyticsApp
