import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgBrowseTable = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5h4v4H5V5zM3 5a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V5zm2 10h4v4H5v-4zm-2 0a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4zM13 4h8v2h-8V4zm8 4h-8v2h8V8zm-8 6h8v2h-8v-2zm8 4h-8v2h8v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBrowseTable
