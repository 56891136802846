import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgMarketplace = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.36 5l.6 5H5.04l.6-5h12.72zM20 3H4l-1 7v2h1v9h10v-9h4v9h2v-9h1v-2l-1-7zM6 19v-7h6v7H6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMarketplace
