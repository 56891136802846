import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgArrowDropDown = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
    </svg>
  )
}

export default SvgArrowDropDown
