import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgExplore = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.5 8.252H17c-.922 0-1.722.506-2.154 1.25H12v-1.25H7v1.25H3.25c-.69 0-1.25.56-1.25 1.25v3.75c0 .691.56 1.25 1.25 1.25h11.596A2.487 2.487 0 0017 17.002h5v-8.75h-2.5zM8.25 9.502h2.5v1.25h-2.5v-1.25zm6.25 5H3.875a.625.625 0 01-.625-.625v-2.5c0-.345.28-.625.625-.625H7v1.25h5v-1.25h2.5v3.75zm3.75 1.25H17c-.692 0-1.25-.56-1.25-1.25v-3.75c0-.69.558-1.25 1.25-1.25h1.25v6.25zm2.5 0H19.5v-6.25h1.25v6.25z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgExplore
