import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisScatter = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M13.533 8.936a1.532 1.532 0 11-3.064 0 1.532 1.532 0 013.064 0zM18.127 14.809a2.808 2.808 0 11-5.617 0 2.808 2.808 0 015.617 0zM8.936 15.83a.766.766 0 11-1.532 0 .766.766 0 011.532 0zM8.255 10.809a1.191 1.191 0 11-2.382 0 1.191 1.191 0 012.382 0z"
        fill="currentColor"
      />
      <path
        d="M19.916 19.915H4.086V4.085h15.83v15.83zm-15.319-.511h14.809V4.595H4.597v14.809z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisScatter
