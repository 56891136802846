import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeFileDocument = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5H5v14h14V5zM5 3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2H5z"
        fill="currentColor"
      />
      <rect x={7} y={8} width={10} height={2} rx={1} fill="currentColor" />
      <rect x={7} y={12} width={10} height={2} rx={1} fill="currentColor" />
    </svg>
  )
}

export default SvgIdeFileDocument
