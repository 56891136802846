import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeFileDashboard = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6H4v12h16V6zM4 4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2H4z"
        fill="currentColor"
      />
      <path
        d="M7 14a1 1 0 112 0v1a1 1 0 11-2 0v-1zM11 12a1 1 0 112 0v3a1 1 0 11-2 0v-3zM15 9a1 1 0 112 0v6a1 1 0 11-2 0V9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIdeFileDashboard
