import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCalendarMonth = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21 6v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14z"
        fill="currentColor"
      />
      <path
        d="M8.99 18H7.783v-3.305l.012-.543.02-.593c-.201.2-.34.332-.419.394l-.656.527-.582-.726 1.84-1.465h.992V18zm5.4 0H13.2v-2.55c0-.316-.054-.552-.16-.708-.105-.159-.27-.238-.497-.238-.305 0-.526.112-.664.336-.138.224-.207.592-.207 1.105V18H10.48v-4.367h.91l.16.558h.067c.117-.2.287-.356.508-.468.221-.115.475-.172.762-.172.653 0 1.096.213 1.328.64h.105c.117-.203.29-.36.516-.472.23-.112.487-.168.773-.168.495 0 .869.127 1.121.383.256.252.383.658.383 1.218V18h-1.195v-2.55c0-.316-.053-.552-.16-.708-.104-.159-.27-.238-.496-.238-.292 0-.51.104-.657.312-.143.209-.214.54-.214.993V18z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendarMonth
