import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import closeWhite from '../../assets/icons/close.svg';
import { Colors } from '../shared/styles';
import { Icon } from '../Icon/Icon';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 33px;
  box-sizing: border-box;
  background-color: #fff;
`;

const Content = styled.div<{ headerHeight: string }>`
  height: calc(100% - ${props => props.headerHeight || '33px'});
  box-sizing: border-box;
  background-color: ${Colors.white};
`;

const CloseIcon = styled.div<{ closeIconWithMargin?: boolean }>`
  height: 13px;
  width: 14px;
  padding: 6px;
  margin-top: ${props => (props.closeIconWithMargin ? '13px' : '0')};
  margin-right: ${props => (props.closeIconWithMargin ? '13px' : '0')};
  cursor: pointer;
`;

const defaultModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  content: {
    padding: '0',
    borderWidth: '0',
    borderRadius: '4px',
    minWidth: '200px',
    minHeight: '200px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    boxShadow: '0px 1px 4px 0 rgba(50, 49, 59, 0.52)',
    fontFamily: 'Assistant',
    transition: '.6s cubic-bezier(1, 0, 0, 1) width',
  },
};

interface GenericModalProps {
  header?: string;
  isModalOpen: boolean;
  closeModal: (event?: React.MouseEvent | React.KeyboardEvent) => void;
  modalStyle?: { overlay: any; content: any; header?: any; closeBtn?: any };
  closeIconWithMargin?: boolean;
}

const GenericModal: React.FunctionComponent<GenericModalProps> = ({
  header,
  isModalOpen,
  closeModal,
  modalStyle,
  closeIconWithMargin,
  children,
}) => {
  if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
  }
  const combinedStyle = {
    overlay: { ...defaultModalStyle.overlay, ...modalStyle?.overlay },
    content: { ...defaultModalStyle.content, ...modalStyle?.content },
  };
  const headerHeight = modalStyle?.header && modalStyle.header.height;
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={combinedStyle}
      ariaHideApp={false}
    >
      <Header style={modalStyle?.header}>
        <div>{header}</div>
        <CloseIcon
          style={modalStyle?.closeBtn}
          onClick={() => closeModal()}
          closeIconWithMargin={closeIconWithMargin}
        >
          {' '}
          <Icon src={closeWhite} />{' '}
        </CloseIcon>
      </Header>
      <Content headerHeight={headerHeight}>{children}</Content>
    </Modal>
  );
};

export default GenericModal;
