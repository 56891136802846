import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgVisBar = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.873 5.873h12.255v2.042H5.873V5.873zM5.873 16.085h3.064v2.043H5.873v-2.043zM5.873 9.277h9.191v2.043H5.873V9.277zM5.873 12.68H12v2.043H5.873V12.68z"
        fill="currentColor"
      />
      <path
        d="M19.916 19.915H4.086V4.085h15.83v15.83zm-15.319-.511h14.809V4.595H4.597v14.809z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisBar
