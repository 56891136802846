import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgSalesAnalytics = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M20.73 5h-4.672a.373.373 0 00-.308.144.422.422 0 00-.144.317c0 .122.048.231.144.327l1.442 1.452-3.24 3.25-1.692-1.693a1.297 1.297 0 00-.952-.394c-.371 0-.689.13-.952.394l-4.971 4.96 1.903 1.904 4.02-4.009L13 13.344c.27.264.59.394.961.394.366 0 .68-.13.943-.394l4.192-4.191 1.317 1.326a.384.384 0 00.318.145.437.437 0 00.307-.116.472.472 0 00.145-.346v-4.7a.422.422 0 00-.145-.318.39.39 0 00-.307-.144zM22 20.304v-1.577H3.577V5.038H2v14.478c0 .217.08.407.24.567.148.148.331.22.548.22H22z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSalesAnalytics
