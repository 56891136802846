import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgSqlRunner = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M14.56 11.31a3.106 3.106 0 001.188-2.434 3.124 3.124 0 00-2.498-3.061V3.25h-1.248v2.564a3.112 3.112 0 00-1.31 5.495l-2.588 4.205C6.42 14.395 5.132 12.73 4.54 10.75H3.247c.628 2.43 2.165 4.476 4.207 5.824l-2.947 4.793 1.063.627 2.952-4.801a9.953 9.953 0 004.429 1.058c1.398 0 2.719-.301 3.927-.818l2.805 4.562 1.063-.627-6.184-10.058-.001-.001zM12.626 7c1.035 0 1.873.84 1.873 1.875a1.874 1.874 0 11-3.745 0c0-1.034.84-1.874 1.872-1.874zm.325 10a8.75 8.75 0 01-3.775-.872l2.615-4.254c.268.073.545.127.835.127.292 0 .568-.053.833-.128l2.76 4.487a8.699 8.699 0 01-3.268.641z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSqlRunner
