import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCalendarDay = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21 6v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14z"
        fill="currentColor"
      />
      <path
        d="M10.388 18H9.181v-3.305l.012-.543.02-.593c-.201.2-.34.332-.418.394l-.657.527-.582-.726 1.84-1.465h.992V18zm2.874.078c-.513 0-.917-.2-1.211-.598-.292-.398-.438-.95-.438-1.656 0-.716.149-1.273.446-1.672.3-.4.71-.601 1.234-.601.55 0 .969.213 1.258.64h.039a4.87 4.87 0 01-.09-.87v-1.4h1.195V18h-.914l-.23-.566H14.5c-.27.43-.684.644-1.238.644zm.418-.95c.304 0 .527-.088.668-.265.143-.177.221-.478.234-.902v-.129c0-.469-.073-.805-.219-1.008-.143-.203-.377-.304-.703-.304a.703.703 0 00-.62.34c-.147.223-.22.55-.22.98 0 .43.075.752.223.969.148.213.36.32.637.32z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendarDay
