import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgChartBar = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M14.8 5v3H5V5h9.8zm4.2 5.5v3H5v-3h14zM11 16v3H5v-3h6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChartBar
