import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgChartWaterfall = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16 4h-3v4h3V4zM8 8h3v5H8V8zM3 13h3v7H3v-7zM18 4h3v11h-3V4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChartWaterfall
