import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgNotificationBellOff = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12.125 21.5c1.117 0 2.031-.9 2.031-2h-4.062c0 1.1.914 2 2.031 2zm6.094-6v-5c0-3.07-1.656-5.64-4.57-6.32V3.5c0-.83-.681-1.5-1.524-1.5a1.51 1.51 0 00-1.523 1.5v.68c-2.905.68-4.57 3.24-4.57 6.32v5L4 17.5v1h16.25v-1l-2.031-2zm-2.032 1H8.063v-6c0-2.48 1.534-4.5 4.063-4.5s4.063 2.02 4.063 4.5v6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNotificationBellOff
