import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgReturn = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M4.628 8.52l2.443-2.43L5.987 5 2.27 8.705a.647.647 0 00-.141.16L2 9.288l.128.424a.647.647 0 00.096.121l3.757 3.757 1.09-1.09-2.443-2.442h12.18c1.003 0 1.863.356 2.577 1.07h.006a3.519 3.519 0 011.07 2.583c0 1.01-.356 1.87-1.07 2.584h-.006c-.715.714-1.574 1.07-2.577 1.07h-3.013v1.539h3.013c1.432 0 2.654-.507 3.666-1.52C21.491 16.373 22 15.148 22 13.712c0-1.435-.509-2.66-1.526-3.672-1.012-1.013-2.234-1.52-3.666-1.52H4.628z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgReturn
