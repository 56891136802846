import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeFileGeneric = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5h2v2h-2V5zm-2 0H5v14h14V9h-3a1 1 0 01-1-1V5zM3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIdeFileGeneric
