import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgReports = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H9v14h10V3zM9 1a2 2 0 00-2 2v14a2 2 0 002 2h10a2 2 0 002-2V3a2 2 0 00-2-2H9zM3 9a2 2 0 012-2v14h11a2 2 0 01-2 2H5a2 2 0 01-2-2V9zm12-4h-4v2h4V5zm-4 4h6v2h-6V9zm6 4h-6v2h6v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgReports
