import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgIdeFileLookMl = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.928 9.372a1 1 0 01-1.856-.743L4 9a89.549 89.549 0 01-.928-.372v-.002l.002-.002.002-.007.008-.018a3.623 3.623 0 01.114-.25 8.37 8.37 0 01.351-.645c.314-.523.8-1.217 1.494-1.911C6.445 4.39 8.688 3 12 3s5.556 1.391 6.957 2.793c.695.694 1.18 1.388 1.494 1.911a8.359 8.359 0 01.44.837l.026.058.007.018.003.007.001.004L20 9l.929-.371a1 1 0 01-1.856.746v-.002a.462.462 0 00-.005-.01l-.007-.016a6.396 6.396 0 00-.325-.613 8 8 0 00-1.193-1.527C16.445 6.11 14.688 5 12 5S7.556 6.109 6.457 7.207a8.002 8.002 0 00-1.193 1.526 6.37 6.37 0 00-.336.64zm0 0a.06.06 0 000 .003v-.003zm14.145.003zM19.072 14.628a1 1 0 011.856.743L20 15l.928.372v.002l-.002.002-.002.007-.008.018a5.588 5.588 0 01-.114.25c-.078.161-.194.383-.351.645a10 10 0 01-1.494 1.911C17.555 19.61 15.312 21 12 21s-5.556-1.391-6.957-2.793a9.999 9.999 0 01-1.495-1.911 8.364 8.364 0 01-.44-.837 4.187 4.187 0 01-.025-.058l-.007-.018-.003-.007-.001-.004L4 15l-.929.371a1 1 0 011.857-.744l.004.01.007.016a6.372 6.372 0 00.325.613c.248.415.637.971 1.193 1.527C7.555 17.89 9.312 19 12 19s4.444-1.109 5.543-2.207a8.005 8.005 0 001.193-1.526 6.398 6.398 0 00.336-.64zm0 0v-.003.003zm-14.145-.003z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIdeFileLookMl
