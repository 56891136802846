import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgCalendarHour = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21 6v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14z"
        fill="currentColor"
      />
      <path
        d="M8.674 18H7.467v-3.305l.012-.543.02-.593c-.201.2-.34.332-.419.394l-.656.527-.582-.726 1.84-1.465h.992V18zm5.522 0h-1.191v-2.55c0-.631-.235-.946-.703-.946-.334 0-.575.113-.723.34-.149.226-.223.594-.223 1.101V18h-1.191v-6.078h1.191v1.238c0 .097-.009.323-.027.68l-.027.351h.062c.266-.427.688-.64 1.266-.64.513 0 .902.138 1.168.414.265.276.398.672.398 1.187V18zm3.264-4.45c.162 0 .296.012.402.036l-.09 1.117a1.368 1.368 0 00-.35-.039c-.381 0-.678.098-.892.293-.21.195-.316.469-.316.82V18h-1.191v-4.367h.902l.176.734h.058c.136-.245.318-.441.547-.59.232-.15.483-.226.754-.226z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendarHour
