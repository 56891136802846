import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgBoard = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.82 3H19c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-.14 0-.27-.01-.4-.03a2.008 2.008 0 01-1.44-1.19c-.1-.24-.16-.51-.16-.78V5c0-.28.06-.54.16-.77A2.008 2.008 0 014.6 3.04c.13-.03.26-.04.4-.04h4.18C9.6 1.84 10.7 1 12 1c1.3 0 2.4.84 2.82 2zm-2.07.5c0-.41-.34-.75-.75-.75s-.75.34-.75.75.34.75.75.75.75-.34.75-.75zM5 5v14h14V5H5zm12 4V7H7v2h10zM7 13v-2h10v2H7zm0 2h7v2H7v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBoard
