import * as React from 'react'
import { GlyphProps } from '../../types'

const SvgChartTable = ({ title, titleId, ...props }: GlyphProps) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.611 9.63h4.722V21H9.611V9.63zM16.222 21h2.834c1.038 0 1.888-.85 1.888-1.889v-9.5h-4.722V21zm2.834-18H4.889C3.85 3 3 3.85 3 4.889v2.833h17.944V4.89c0-1.039-.85-1.889-1.888-1.889zM3 19.111C3 20.15 3.85 21 4.889 21h2.833V9.611H3v9.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChartTable
